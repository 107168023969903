(function( $ ){ 
    jQuery.fn.ajaxForm = function(options){
        var settings = $.extend({
            callback: function(){}
        }, options);
        var form = $(this);
      /*  form.find('[data-plugin="select2"]').select2({
            escapeMarkup: function(markup) {
                return markup;
            }
        });*/
        form.trigger('ajaxForm.init');
        form.trigger('ajaxForm.inited');
        form.on('submit', function(e){
            e.preventDefault();
            if(form.attr('data-load') == 1){
                return;
            }
            var url = $(this).attr('action');
            var params = $(this).serialize();
            var type = $(this).attr('method');
            $(this).find('.form-control').removeClass('is-invalid');
            $.ajax({
                type: type,
                url: url,
                data: params,
                beforeSend: function(){
                    form.attr('data-load', 1);
                },
                complete: function(){
                    form.attr('data-load', 0);
                },
                success: function(data){
                    if(data.errors){
                        $.each(data.errors, function(key, value){
                            form.find('.form-control[name="' + key + '"]').addClass('is-invalid');
                            form.find('.form-control[name="' + key + '"]').closest('.form-group').find('.invalid-feedback').html(value);
                        });
                        alertError(data.message);
                        return;
                    }
                    if(data.success){
                        alertSuccess(data.success); 
                    }
                    settings.callback(data);
                    form.trigger('ajaxForm.submited', data);
                }
            });
        });
    };
})( jQuery );
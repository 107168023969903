import $ from 'jquery';
window.$ = window.jQuery = $;
window.Popper = require('popper.js');
window.lodash = require('lodash');

//require('./vue/vue.js');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require('../assets/js/bootstrap.bundle.min.js');
require( '../assets/js/tables/datatables/datatables.min.js');
require('../assets/js/inputmask.js');
require('../assets/js/select2.min.js');
require('../assets/js/fab.min.js');

window.PNotify = require('../assets/js/pnotify.min.js');
window.echarts = require('../assets/js/echarts.min.js');


import moment from 'moment';
window.moment = moment;
moment.locale('ru');
require('moment-timezone');

require('daterangepicker');
require('../assets/js/app.js');
require('../assets/js/switch.js');
require('../assets/js/search.js');
require('../assets/js/ajaxForm.js');
require('../assets/js/custom.js');

window.Dropzone = require('dropzone');

require('nestable');



